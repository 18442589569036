import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';

import childRoutes from './children';
import { ReportsRouteNames, ReportsRoutePathNames } from '../enums';

const App = () => import('./../App.vue');

const rootRoute: RouteRecordRaw = {
  path: buildRootURL(ReportsRoutePathNames.REPORTS),
  name: ReportsRouteNames.REPORTS,
  redirect: {
    name: ReportsRouteNames.SALES_SUMMARY,
  },
  meta: {
    requireAuth: true,
  },
  component: App,
  children: childRoutes,
};

export default rootRoute;
