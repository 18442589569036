import { computed, defineComponent, h, onMounted, onUnmounted, resolveComponent, watch } from 'vue';
import { DataTable } from './../../../core';
import { useStorePagination, useStoreAPIAction } from '@centric-os/stores';
import { useBreakpoints } from '@centric-os/helpers';
import { useRoute } from 'vue-router';
import { isNil } from 'lodash';

export const StoreTable = defineComponent({
  components: {
    DataTable,
  },
  inheritAttrs: false,
  emits: ['page', 'sort'],
  props: {
    ...DataTable?.props,
    actionName: { type: String, required: true },
    actionParam: { type: [String, Boolean], default: null },
    runActionOnMount: { type: Boolean, default: true },
    store: { type: Object, required: true },
    valueField: { type: String, required: true },
    updateQuery: { type: Boolean, default: true },
  },
  setup(props, { attrs, emit, slots, expose }) {
    const { loading } = useStoreAPIAction(props.store, props.actionName);
    const {
      limit,
      page: storePage,
      queryParams,
      metaParams,
      sortBy,
      sortOrderNumeric,
      reset,
    } = useStorePagination(props.store, { updateQuery: props.updateQuery });
    const page = computed(() => storePage.value - 1);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.smaller('sm');
    const pageLinkSize = computed(() => (isMobile.value ? 3 : 5));

    const route = useRoute();
    const originalRouteName = route.name;

    const onPage = (event: any) => {
      if (limit.value !== event.rows) {
        limit.value = event.rows;
        storePage.value = 1;
      } else {
        storePage.value = event.page + 1;
      }

      emit('page', event);
    };

    const onSort = (event: any) => {
      sortBy.value = event.sortField;
      sortOrderNumeric.value = event.sortOrder;
      storePage.value = 1;
      emit('sort', event);
    };

    const triggerAction = () => {
      if (isNil(props.actionParam)) {
        return props.store[props?.actionName]();
      }
      return props.store[props?.actionName](props?.actionParam);
    };

    const tableProps = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { actionName, store, valueField, ...p } = props;

      const hover = props.store[props.valueField]?.length > 0 ? true : false;
      const selectionMode = hover ? props.selectionMode : '';

      return {
        ...p,
        ...attrs,
        first: limit.value * page.value,
        lazy: true,
        loading: p.loading || loading.value,
        pageLinkSize: pageLinkSize.value,
        rows: limit.value,
        sortField: sortBy.value,
        sortOrder: sortOrderNumeric.value,
        totalRecords: metaParams.value.totalResults,
        rowHover: hover,
        value: props.store[props.valueField],
        selectionMode,
        onPage,
        onSort,
      };
    });

    watch(
      () => props.actionParam,
      () => {
        triggerAction();
      },
    );

    onMounted(() => {
      if (props.sortField && sortBy.value !== props.sortField) {
        sortBy.value = props.sortField;
      } else if (props.runActionOnMount) {
        triggerAction();
      }

      onUnmounted(() => {
        queryWatch();
        reset();
      });

      const queryWatch = watch(queryParams.value, () => {
        if (originalRouteName === route.name) {
          if (!loading.value || !props.loading) {
            triggerAction();
          }
        }
      });

      // this is a workaround due to inability to set aria attributes to prime vue inner components
      const rowsPerPage = document.getElementsByClassName('p-dropdown-trigger');
      if (rowsPerPage) {
        for (let index = 0; index < rowsPerPage.length; index++) {
          const element = rowsPerPage[index];
          element.setAttribute('aria-label', `numberOfRowsButton`);
        }
      }

      const paginatorFirst = document.getElementsByClassName('p-paginator-first');
      if (paginatorFirst) {
        for (let index = 0; index < paginatorFirst.length; index++) {
          const element = paginatorFirst[index];
          element.setAttribute('aria-label', `paginatorGoFirstPage`);
        }
      }

      const paginatorPrev = document.getElementsByClassName('p-paginator-prev');
      if (paginatorPrev) {
        for (let index = 0; index < paginatorPrev.length; index++) {
          const element = paginatorPrev[index];
          element.setAttribute('aria-label', `paginatorGoBackPage`);
        }
      }

      const paginatorLast = document.getElementsByClassName('p-paginator-last');
      if (paginatorLast) {
        for (let index = 0; index < paginatorLast.length; index++) {
          const element = paginatorLast[index];
          element.setAttribute('aria-label', `paginatorGoLastPage`);
        }
      }

      const paginatorNext = document.getElementsByClassName('p-paginator-next');
      if (paginatorNext) {
        for (let index = 0; index < paginatorNext.length; index++) {
          const element = paginatorNext[index];
          element.setAttribute('aria-label', `paginatorGoNextPage`);
        }
      }
    });
    expose({ ...tableProps.value, triggerAction });

    return () => {
      return h(
        //@ts-ignore
        resolveComponent('DataTable'),
        tableProps.value,
        slots,
      );
    };
  },
});
