<template>
  <div :class="rootClasses" class="checkbox-container">
    <div class="flex align-items-center flex-wrap">
      <label v-if="$slots.labelTop" :for="id" class="flex-grow-1">
        <slot name="labelTop" />
      </label>

      <PVCheckbox v-bind="$attrs" :inputId="id" v-model="value">
        <template v-if="$slots.icon" #icon>
          <slot name="icon" />
        </template>
      </PVCheckbox>

      <label
        v-if="$slots.label"
        :for="id"
        class="cursor-pointer label-right ml-2"
        :class="{ 'p-disabled': disabled }"
      >
        <slot name="label" />
      </label>

      <span v-if="$slots.valueText" class="ml-2 font-normal" :id="`${id}-value-text`">
        <slot name="valueText" />
      </span>
    </div>

    <span v-if="$slots.helperText" class="p-invalid p-error mt-2 caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { watchEffect, computed, useAttrs } from 'vue';
import PVCheckbox from 'primevue/checkbox';
import { useInputDisabled, useInputRequired } from '../composables';

defineOptions({
  name: 'Checkbox',
  inheritAttrs: false,
});

const attrs = useAttrs();

defineProps({
  id: {
    type: String,
    required: true,
  },
});

const modelValue = defineModel<any>();

const value = computed<any>({
  get() {
    return modelValue.value;
  },
  set(newValue) {
    // need this to be able to validate array values using vee validate
    if (newValue && Array.isArray(newValue)) {
      const value = newValue.length > 0 ? newValue : null;
      modelValue.value = value ?? [];
    } else {
      modelValue.value = newValue;
    }
  },
});

const { disabled, classes: disabledClasses } = useInputDisabled(attrs.disabled as boolean);

const { required, classes: requiredClasses } = useInputRequired(attrs.required as boolean);

const rootClasses = computed(() => {
  return [
    'field-checkbox',
    disabledClasses.value,
    requiredClasses.value,
    {
      'p-error': Boolean((attrs.class as string)?.includes('p-invalid')),
    },
  ];
});

watchEffect(() => {
  disabled.value = Boolean(attrs.disabled);
  required.value = Boolean(attrs.required);
});
</script>

<style scoped lang="scss">
.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}
</style>
