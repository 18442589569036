<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <span :class="containerClass">
      <input
        :class="['p-inputtext p-component', { 'p-filled': filled }]"
        :placeholder="placeholder"
        v-bind="$attrs"
        @focus="focused = true"
        @blur="focused = false"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @input="checkInput"
        ref="inputRef"
        :id="inputId"
      />
    </span>

    <slot v-if="$slots.endAdorment" name="endAdorment" />

    <template v-if="showClear">
      <i
        class="material-symbols-rounded cursor-pointer text-xl"
        @click="clearValue"
        v-if="numberValue || numberValue === 0"
      >
        cancel
      </i>
    </template>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script lang="ts">
export default { name: 'InputNumber', inheritAttrs: false };
</script>

<script setup lang="ts">
import { computed, useAttrs, watch, useSlots, ref, type PropType } from 'vue';
import { useInputState } from '../composables';
import { useCurrencyInput, type CurrencyInputOptions } from 'vue-currency-input';
import { watchDebounced } from '@vueuse/core';
import { isEqual, isNil } from 'lodash';
import { DEFAULT_VUE_CURRENCY_INPUT_OPTIONS } from '.';

const emit = defineEmits(['clear']);

const props = defineProps({
  placeholder: String,
  showClear: {
    type: Boolean,
    default: false,
  },
  noUndefinedValue: {
    type: Boolean,
    default: false,
  },
  inputId: {
    type: String,
  },
  options: {
    type: Object as PropType<CurrencyInputOptions>,
    default: () => DEFAULT_VUE_CURRENCY_INPUT_OPTIONS,
  },
});

const value = defineModel<any>();

const attrs = useAttrs();
const slots = useSlots();

const { inputRef, numberValue, setValue } = useCurrencyInput(props.options, false);

watch(value, (newVal: number) => {
  // This keeps model and input in sync when the value is changed from outside
  if (!isEqual(inputRef.value?.value, newVal)) {
    setValue(newVal);
  }
});

const { focused, hovered, classes } = useInputState(attrs);

const rootClasses = computed(() => [
  ...classes.value,
  { 'p-input-icon-right': slots.endAdorment || props.showClear },
]);

const filled = computed(() => {
  return numberValue.value !== null && numberValue.value.toString().length > 0;
});

const containerClass = computed(() => {
  return [
    'p-inputnumber p-component p-inputwrapper',
    {
      'p-inputwrapper-filled': filled.value,
      'p-inputwrapper-focus': focused.value,
    },
  ];
});

const isDirty = ref<boolean>(false);

const checkInput = (event: InputEvent) => {
  isDirty.value = true;
  const target = event.target as HTMLInputElement;
  if (props.noUndefinedValue && ((!event.data && target?.value === '0.0') || !target?.value)) {
    // Prevent empty field
    inputRef.value.value = 0;
    setValue(0);
  }
  if (event.data === '.' && (isNil(numberValue.value) || target?.value === '.')) {
    setValue(0);
  }
};

watchDebounced(numberValue, (val) => (value.value = val), {
  debounce: 0,
});

watch(
  () => value,
  (first, second) => {
    if (isNil(first) && !isNil(second) && !isNil(numberValue.value)) {
      clearValue();
    }
  },
);

const clearValue = () => {
  setValue(null);
  emit('clear');
};

defineExpose({ numberValue, setValue, isDirty, inputRef });
</script>
