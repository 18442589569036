import type { RouteRecordRaw } from 'vue-router';

import listRoute from './local-menu-group-list';
import detailsRoute from './local-menu-group-details';
import localBrandDetailsRoute from './local-brand-details';
import entityManagementRoutes from './manage-brand-sub-entity';

export default [
  listRoute,
  detailsRoute,
  localBrandDetailsRoute,
  ...entityManagementRoutes,
] as RouteRecordRaw[];
