import cdlid from '@compassdigital/id';
import type { AxiosRequestConfig } from 'axios';
import {
  type CDLocation,
  type CdlOrder,
  FeatureFlags,
  type Location,
  type LocationResponse,
  type Order,
  OrderPaginationQueryKeys,
  type OrderPaginationQueryParams,
  type OrderResponse,
  OrderSortingKeys,
  OrderStatusResponses,
  type P2User,
  type PaginationConfig,
  type QueryParams,
  type RecordEntityId,
  type RecordList,
  type RefundReasons,
  type OrderItem,
  SortOrder,
  PermissionScope,
  type IndexedReviews,
  type ReviewOrderItem,
} from '@centric-os/types';
import { defineStore, type StateTree } from 'pinia';
import { DateTime } from 'luxon';
import { CountryPrefix, ORDER_TYPES, OrdersLocalePrefix } from '@centric-os/orders/src/enums';
import { useAuthStore, useSplitIoStore } from '@centric-os/stores';
import type { RefundTransaction } from '@compassdigital/sdk.typescript/interface/order';
import type { ShoppingCart } from '@compassdigital/sdk.typescript/interface/shoppingcart';
import type { Address } from '@compassdigital/sdk.typescript/interface/location';
import type { DrainedTender } from '@compassdigital/sdk.typescript/interface/mealplan';
import { useI18n } from 'vue-i18n';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import { currency } from '../../utils/currency';

interface State extends StateTree {
  order: CdlOrder;
  location: CDLocation;
  shoppingcart: ShoppingCart;
  orders: OrderResponse[];
  site_names: Location[];
  loadSiteNames: boolean;
  brand_names: Location[];
  loadBrandNames: boolean;
  isMarketPlaceOrder: boolean;
  reviews: ReviewOrderItem[];
  reasons: Array<RefundReasons>;
  brands: any;
  isCanadianSite: boolean;
  refundItems: any;
  refundDetails: {
    isFullRefund: boolean;
    originalShoppingcart: ShoppingCart;
    refunds: {
      refundItems: OrderItem[];
      time: string;
      processedBy: string;
      total: string;
      paymentMethod: string;
      fpRequestId?: string;
      id: string;
    }[];
  } | null;
}

const path = '/search';

export const defaultOrderPaginationConfig: PaginationConfig = {
  queryParams: <OrderPaginationQueryParams>{
    [OrderPaginationQueryKeys.LIMIT]: 25,
    [OrderPaginationQueryKeys.PAGE]: 1,
    [OrderPaginationQueryKeys.SORT_BY]: OrderSortingKeys.DATE_CREATED,
    [OrderPaginationQueryKeys.SORT_ORDER]: SortOrder.DESC,
    [OrderPaginationQueryKeys.DATE_CREATED_START]: DateTime.local().startOf('day').toUTC().toISO(),
    [OrderPaginationQueryKeys.DATE_CREATED_END]: DateTime.local().endOf('day').toUTC().toISO(),
    [OrderPaginationQueryKeys.SOURCE_ID]: undefined,
    [OrderPaginationQueryKeys.PICKUP_NAME]: undefined,
    [OrderPaginationQueryKeys.PICKUP_ID]: undefined,
    [OrderPaginationQueryKeys.SOURCE_GROUP_ID]: undefined,
    [OrderPaginationQueryKeys.SOURCE_BRAND_ID]: undefined,
    [OrderPaginationQueryKeys.REFUND_STATUS]: undefined,
    [OrderPaginationQueryKeys.PAYMENT_CATEGORY]: undefined,
    [OrderPaginationQueryKeys.EMAIL]: undefined,
    [OrderPaginationQueryKeys.ORDER_TYPE]: undefined,
    [OrderPaginationQueryKeys.STATUS]: undefined,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};
export const useOrderStore = defineStore('orders', {
  state: (): State => ({
    order: null,
    location: null,
    orders: [],
    site_names: [],
    loadSiteNames: false,
    brand_names: [],
    loadBrandNames: false,
    isCanadianSite: false,
    fetchingOrders: false,
    shoppingcart: {},
    isMarketPlaceOrder: false,
    refundItems: [],
    refundDetails: null,
    reviews: [],
    reasons: [
      {
        label: 'Quality Issues',
        items: [
          { label: { en: 'Food quality' }, value: 'Food quality' },
          {
            label: { en: 'Missing or incorrect items' },
            value: 'Missing or incorrect items',
          },
          {
            label: { en: 'Food-borne illness allegation' },
            value: 'Food-borne illness allegation',
          },
          {
            label: { en: 'Foreign object in food' },
            value: 'Foreign object in food',
          },
          {
            label: { en: 'Incorrect temperature or preparation' },
            value: 'Incorrect temperature or preparation',
          },
        ],
      },
      {
        label: 'Service Issue',
        items: [
          {
            label: { en: 'Late delivery (not pick up)' },
            value: 'Late delivery (not pick up)',
          },
          { label: { en: 'Incorrect price' }, value: 'Incorrect price' },
          { label: { en: 'Duplicate charge' }, value: 'Duplicate charge' },
          {
            label: { en: 'Global KDS or app issue' },
            value: 'Global KDS or app issue',
          },
          {
            label: { en: 'Site-wide KDS or app issue' },
            value: 'Site-wide KDS or app issue',
          },
          {
            label: { en: 'Incorrect operating hours' },
            value: 'Incorrect operating hours',
          },
          { label: { en: 'Did not purchase' }, value: 'Did not purchase' },
        ],
      },
      {
        label: 'Non-Issue',
        items: [
          {
            label: { en: 'Order cancellation request' },
            value: 'Order cancellation request',
          },
          { label: { en: 'Test order' }, value: 'Test order' },
        ],
      },
    ],
    brands: [],
    refundStatuses: [
      { value: 'none', name: 'Paid' },
      { value: 'full', name: 'Fully Refunded' },
      { value: 'partial', name: 'Partially Refunded' },
    ],
    paymentCategories: [
      { value: 'digital wallet', name: 'Digital wallet' },
      { value: 'badge pay', name: 'Badge pay' },
      { value: 'credit card', name: 'Credit card' },
      { value: 'mealplan', name: 'Meal plan' },
    ],
    orderTypes: [
      { value: ORDER_TYPES.DELIVERY, name: 'Drop-off' },
      { value: ORDER_TYPES.PICKUP, name: 'Pickup' },
      { value: ORDER_TYPES.SCAN_AND_GO, name: 'Scan & Pay' },
      { value: ORDER_TYPES.FRICTIONLESS, name: 'Frictionless' },
    ],
    orderStatuses: [
      { value: OrderStatusResponses.READY, name: 'Ready' },
      { value: OrderStatusResponses.ACCEPTED, name: 'Accepted' },
      { value: OrderStatusResponses.DELIVERED, name: 'Delivered' },
      { value: OrderStatusResponses.IN_PROGRESS, name: 'In Progress' },
      { value: OrderStatusResponses.OUT_FOR_DELIVERY, name: 'Out For Delivery' },
      { value: OrderStatusResponses.CREATED, name: 'Created' },
    ],
  }),
  getters: {
    isRefundDisabled: (state) => {
      if (!state.order) return true;
      const auth = useAuthStore();
      const hasRefundPermissions = auth.hasPermissions([PermissionScope.refundOrder]);
      const isValidPaymentMethod = isPaymentMethodRefundsEnabled(state.order, state.shoppingcart);
      const isFullyRefunded =
        state.refundDetails?.isFullRefund && state.order?.shoppingcart.items.length === 0;
      const countryRefundEnabled = isCountryRefundsEnabled(state.order.location_brand?.address);
      const orderTotalFree =
        !state.order.meal_swipes &&
        state.order.shoppingcart.total.amount === 0 &&
        !state.order.shoppingcart.total.meals;
      return (
        !hasRefundPermissions ||
        !isValidPaymentMethod ||
        isFullyRefunded ||
        !countryRefundEnabled ||
        orderTotalFree
      );
    },
    refundDisabledReason: (state): string => {
      const reason = '';
      if (!state.isRefundDisabled) return reason;
      const { t } = useI18n();
      if (!isCountryRefundsEnabled(state.order?.location_brand?.address)) {
        return t(`${OrdersLocalePrefix.ORDER_DETAILS}.refund.disabledReasons.country`);
      }
      if (!isPaymentMethodRefundsEnabled(state.order, state.shoppingcart)) {
        return t(`${OrdersLocalePrefix.ORDER_DETAILS}.refund.disabledReasons.paymentMethod`);
      }
      if (state.refundDetails?.isFullRefund && state.order?.shoppingcart.items.length === 0) {
        return t(`${OrdersLocalePrefix.ORDER_DETAILS}.refund.disabledReasons.noItems`);
      }
      const auth = useAuthStore();
      if (!auth.hasPermissions([PermissionScope.refundOrder])) {
        return t(`${OrdersLocalePrefix.ORDER_DETAILS}.refund.disabledReasons.permission`);
      }
      if (state.order.shoppingcart.total.amount === 0) {
        return t(`${OrdersLocalePrefix.ORDER_DETAILS}.refund.disabledReasons.freeItems`);
      }
      return reason;
    },
    reviewsMap: (state): IndexedReviews => {
      return state.reviews.reduce((acc: IndexedReviews, review) => {
        acc[review.taxonomy?.item] = review;
        return acc;
      }, {});
    },
  },
  actions: {
    async getOrders(config: AxiosRequestConfig = {}, filter?: any) {
      if (this.fetchingOrders) return;
      this.fetchingOrders = true;
      try {
        const queryParams = this.pagination.queryParams;
        const url = `${path}/order`;
        Object.assign(queryParams, filter);
        // @ts-ignore
        const response = await this.api.get<RecordList<Order>>(url, {
          ...config,
          params: {
            ...queryParams,
            [OrderPaginationQueryKeys.SORT_ORDER]:
              queryParams[OrderPaginationQueryKeys.SORT_ORDER] ||
              defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.SORT_ORDER],
            [OrderPaginationQueryKeys.SORT_BY]:
              queryParams[OrderPaginationQueryKeys.SORT_BY] ||
              defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.SORT_BY],
            [OrderPaginationQueryKeys.PAGE]:
              queryParams[OrderPaginationQueryKeys.PAGE] ||
              defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.PAGE],
            [OrderPaginationQueryKeys.LIMIT]:
              queryParams[OrderPaginationQueryKeys.LIMIT] ||
              defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.LIMIT],
            [OrderPaginationQueryKeys.DATE_CREATED_START]:
              queryParams[OrderPaginationQueryKeys.DATE_CREATED_START] ||
              defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.DATE_CREATED_START],
            [OrderPaginationQueryKeys.DATE_CREATED_END]:
              queryParams[OrderPaginationQueryKeys.DATE_CREATED_END] ||
              DateTime.fromJSDate(
                new Date(queryParams[OrderPaginationQueryKeys.DATE_CREATED_START] as string),
              )
                .endOf('day')
                .toUTC()
                .toISO(),
            ...filter,
          },
        });
        const { results, meta } = response.data;
        this.setFilterValuesFromQuery(results, queryParams);
        this.orders = results.map((o: Order) => {
          const orderType = o.details && o.details.order_type;
          const marketPlaceOrders = o.market_place_stations;

          const obj: OrderResponse = {
            source_id: o.source_id,
            contact_number: o.details.contact_number,
            delivery: undefined,
            destination: o.details.destination,
            pickup_id: o.pickup_id,
            order_date: DateTime.fromISO(o.date_created)
              .setZone(o.source_brand.timezone)
              .toFormat('MMM d, t'),
            instructions: o.details.instructions,
            name: o.pickup_name,
            ordered: o.date.created,
            pickup: orderType === ORDER_TYPES.PICKUP ? o.pickup : undefined,
            progress: o.is,
            total: o.total || String(0),
            type: orderType || ORDER_TYPES.PICKUP,
            _raw: o,
            site_name: o.source_group?.name,
            brand_name: marketPlaceOrders
              ? o.market_place_stations.map((e) => e.name).join(', ')
              : o.source_brand?.name,
            payment_type: getOrderPaymentType(o),
            refund_status: o.refund_status,
            payment_category:
              o.payment_category == 'N/A'
                ? o.shoppingcart?.payment_method?.credit_card?.card_type
                  ? 'Credit Card'
                  : o.payment_category
                : o.payment_category,
            email: getEmails(o.email || ''),
          };

          return obj;
        }) as OrderResponse[];

        this.updatePaginationWithMeta(meta);
      } finally {
        this.fetchingOrders = false;
      }
    },
    async getOrder(id: RecordEntityId, config: AxiosRequestConfig = {}) {
      if (this.order?.id !== id) {
        const params = {
          _query: '{ALL,shoppingcart{ALL}, location_brand{ALL}}',
        };
        const response = await this.cdlApi.get<CdlOrder>(`order/${id}`, {
          ...config,
          params,
        });

        if (response.data.location_brand && typeof response.data.location_brand !== 'string') {
          const { data: locationData } = await this.cdlApi.get(
            `location/${response.data.location_brand.location}`,
          );
          const { data: locationGroupData } = await this.cdlApi.get(
            `location/group/${response.data.location_brand.group}?include_estimated_wait_time=false`,
          );
          this.location = locationData;
          this.location.location_group = locationGroupData;
          this.isCanadianSite = response.data.location_brand.address.country === 'CA';
        }
        this.isMarketPlaceOrder =
          response.data.meta &&
          response.data.meta.market_place_stations &&
          response.data.meta.market_place_label;

        if (this.isMarketPlaceOrder && !response.data.meta.refunds) {
          const brandItems = response.data.meta.market_place_stations.map((e) => {
            const items = response.data.shoppingcart.items.filter((item) => e === item.meta.brand);
            return {
              id: e,
              label: {
                en: items[0].meta.brand_label.en,
              },
              items,
            };
          });
          this.brands = brandItems;
        }
        if (
          response.data.meta.refunds &&
          response.data.meta.refunds.length > 0 &&
          response.data.refunds
        ) {
          const orderedRefunds = response.data.meta.refunds.toSorted((a, b) => {
            return new Date(a.refund_date).getTime() - new Date(b.refund_date).getTime();
          });

          const historyOfShoppingCarts = await Promise.all(
            orderedRefunds.map((refund) => {
              return this.getShoppingCart(refund.original_shoppingcart);
            }),
          );
          const originalShoppingcart = historyOfShoppingCarts[0];
          historyOfShoppingCarts.push(response.data.shoppingcart);
          const refunds: {
            refundItems: OrderItem[];
            time: string;
            processedBy: string;
            total: string;
            paymentMethod: string;
            id: string;
          }[] = await Promise.all(
            orderedRefunds.map(async (refund, refundIndex) => {
              const time = new Date(refund.refund_date).toLocaleTimeString('en-gb', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              });

              // Refund items
              const cartBeforeRefund = historyOfShoppingCarts[refundIndex];
              const cartAfterRefund = historyOfShoppingCarts[refundIndex + 1];
              const refundItems = cartBeforeRefund.items.reduce((acc, previousCartItem) => {
                if (cartAfterRefund.items) {
                  const intersectingItem = cartAfterRefund.items.find(
                    (currentCartItem) => currentCartItem.id === previousCartItem.id,
                  );
                  if (!intersectingItem) {
                    acc.push(previousCartItem);
                    return acc;
                  }
                  if (previousCartItem.quantity !== intersectingItem.quantity) {
                    const newCartItem = cloneDeep(previousCartItem);
                    newCartItem.quantity = previousCartItem.quantity - intersectingItem.quantity;
                    acc.push(newCartItem);
                  }
                }

                return acc;
              }, []);

              let processedBy = '-';
              if (refund.issued_by) {
                try {
                  const encoded_user_id = cdlid('user', 'cdl', 'user', refund.issued_by);
                  const p2User = await this.getUser(encoded_user_id);
                  processedBy = `${p2User.name.first} ${p2User.name.last}`;
                } catch (err) {
                  console.error(err);
                }
              }

              let total = '';

              if (refund.refund_amount !== undefined) {
                total = refund.refund_amount;
              } else {
                /* void transactions were removed in newer version of provider.payment.fp; this code block supports backwards compatibility when refunding old orders  */

                /* refund.amount was changed to refund.refund_amount in new api version, checking both for backwards compatibility */
                const refund_amount = refund.refund_amount || refund.amount;
                if (refund_amount === 0 && orderedRefunds.length === 1) {
                  /* transaction_remainder_amount = 0 */
                  total = refund.void_transaction.amount;
                } else {
                  if (!refund.void_transaction) {
                    total = refund_amount;
                  } else {
                    const refundItem =
                      parseFloat(refund.void_transaction.amount) - parseFloat(refund_amount);
                    total = refundItem.toFixed(2);
                  }
                }
              }
              if (!total && refund.secondary_payment_refund) {
                total =
                  originalShoppingcart.total.amount +
                  (originalShoppingcart.payment_method.meal_swipes?.total || 0);
              }

              const totalRefundString = `${total ? currency(total) : '-'}`;
              const id = refund.id || refund.secondary_payment_refund?.id || 'none';
              const fpRequestId = refund.requestID || refund.secondary_payment_refund?.requestID;
              return {
                refundItems,
                time,
                processedBy,
                total: totalRefundString,
                paymentMethod: getTransactionPaymentMethod(
                  response.data,
                  originalShoppingcart,
                  refund,
                ),
                id: id as string,
                fpRequestId,
              };
            }),
          );

          if (this.isMarketPlaceOrder) {
            const originalBrandItems = response.data.meta.market_place_stations.map((e) => {
              const items = originalShoppingcart.items.filter((item) => e === item.meta.brand);
              return {
                id: e,
                label: {
                  en: items[0].meta.brand_label.en,
                },
                items,
              };
            });
            this.brands = originalBrandItems;
          }
          this.refundDetails = {
            isFullRefund:
              response.data.shoppingcart.total.amount === 0 &&
              response.data.shoppingcart.items.length === 0,
            originalShoppingcart,
            refunds,
          };
        }

        this.order = response.data;
        this.shoppingcart = response.data.shoppingcart;
      }
    },
    async getShoppingCart(id: RecordEntityId, config: AxiosRequestConfig = {}) {
      const response = await this.cdlApi.get<ShoppingCart>(`shoppingcart/${id}`, {
        ...config,
      });
      return response.data;
    },

    async refundOrder(refunditems) {
      await this.cdlApi.patch(
        `/order/${this.order.id}/refund`,
        {
          refunds: refunditems,
        },
        {
          headers: { 'x-cdl-platform': 'web' },
        },
      );
    },

    async getSiteNames(site_name: string, config: AxiosRequestConfig = {}) {
      this.loadSiteNames = true;
      const url = `${path}/location`;
      const params = {
        limit: 20,
        source_type: 'group',
        name: site_name || undefined,
      };
      const response = await this.api.get<LocationResponse>(url, {
        ...config,
        params,
      });
      this.loadSiteNames = false;
      const { results } = response.data;
      this.site_names = results;
    },

    async getBrandNames(site_id: string, config: AxiosRequestConfig = {}) {
      this.loadBrandNames = true;
      const url = `${path}/location`;
      const params = {
        limit: 200,
        source_type: 'brand',
        source_group_parent: site_id || undefined,
      };
      const response = await this.api.get<LocationResponse>(url, {
        ...config,
        params,
      });
      this.loadBrandNames = false;
      const { results } = response.data;
      this.brand_names = results;
    },
    /*
     * Method to set visible value in Site Name dropdown on page refresh
     * */
    setFilterValuesFromQuery(results: Order[], queryParams: QueryParams) {
      const siteInfoNotAvailableInDropdown = !this.site_names.find(
        (site) => site.source_id === queryParams.source_group_id,
      );
      const siteNameFromOrder =
        !!results.length &&
        queryParams.source_group_id &&
        results.find((o) => o?.source_group?.name)?.source_group?.name;
      if (siteInfoNotAvailableInDropdown && siteNameFromOrder) {
        this.site_names.push({
          name: results.find((o) => o?.source_group?.name).source_group.name,
          source_id: <string>queryParams.source_group_id,
        });
      }
    },
    resetOrderState() {
      this.order = null;
      this.brands = [];
      this.refundDetails = null;
    },
    async getUser(id: RecordEntityId, config: AxiosRequestConfig = {}) {
      const response = await this.cdlApi.get<P2User>(`user/${id}`, {
        ...config,
      });
      return response.data;
    },
    async exportOrders(includePII: boolean) {
      const queryParams = pickBy(this.pagination.queryParams, (value) => value !== ''); // select non empty query params
      const params = {
        ...queryParams,
        [OrderPaginationQueryKeys.SORT_ORDER]:
          queryParams[OrderPaginationQueryKeys.SORT_ORDER] ||
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.SORT_ORDER],
        [OrderPaginationQueryKeys.SORT_BY]:
          queryParams[OrderPaginationQueryKeys.SORT_BY] ||
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.SORT_BY],
        [OrderPaginationQueryKeys.PAGE]:
          queryParams[OrderPaginationQueryKeys.PAGE] ||
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.PAGE],
        [OrderPaginationQueryKeys.LIMIT]:
          queryParams[OrderPaginationQueryKeys.LIMIT] ||
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.LIMIT],
        [OrderPaginationQueryKeys.DATE_CREATED_START]:
          queryParams[OrderPaginationQueryKeys.DATE_CREATED_START] ||
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.DATE_CREATED_START],
        [OrderPaginationQueryKeys.DATE_CREATED_END]:
          queryParams[OrderPaginationQueryKeys.DATE_CREATED_END] ||
          DateTime.fromJSDate(
            new Date(queryParams[OrderPaginationQueryKeys.DATE_CREATED_START] as string),
          )
            .endOf('day')
            .toUTC()
            .toISO(),
      };
      const response = await this.cdlApi.get('centricos/orders/export', {
        params: {
          ...params,
          include_pii: includePII,
        },
      });

      return response.data;
    },
    async getReviewOrderItems(orderId: string) {
      const { data: response } = await this.cdlApi.get(`centricos/review/${orderId}/order-items`);
      this.reviews = response.reviews;
    },
  },
  pagination: true,
  paginationConfig: defaultOrderPaginationConfig,
});

function getOrderPaymentType(order: Order | CdlOrder) {
  const payments = [];

  if (order.payment?.credit_card?.card_type) {
    payments.push(order.payment.credit_card.card_type);
  }
  const cashlessMethods = getCashlessPaymentMethodLabels(order);
  if (cashlessMethods.length) {
    payments.push(...cashlessMethods);
  }
  if (order.payment && order.payment.digital_wallet_pay) {
    payments.push(order.payment.digital_wallet_pay);
  }

  if (order.meta?.transaction?.drained_tender_breakdown?.length > 0) {
    const payment = formatDrainedTenderMealplanPayment(
      order.meta.transaction?.name || order.mealplan.name,
      order.meta.transaction?.drained_tender_breakdown
        .map((drained_tender: DrainedTender) => drained_tender.name)
        .join(', '),
    );
    payments.push(payment);
  } else if (order.mealplan) {
    const payment = formatMealplanPayment(order.mealplan.name, order.mealplan.tender);
    payments.push(payment);
  }

  if (order.meal_swipes) {
    const payment = formatMealplanPayment(order.meal_swipes.tender_name, order.meal_swipes.tender);
    payments.push(payment);
  }

  if (order.shoppingcart?.payment_method?.credit_card?.card_type) {
    return order.shoppingcart?.payment_method?.credit_card?.card_type;
  }

  return payments.join(', ') || 'N/A';
}

function getEmails(email: string) {
  const str = email.split('@')[0];
  return `${str[0]}***${str[str.length - 1]}@${email.split('@')[1]}`;
}

function formatMealplanPayment(name: string, tenderId: string) {
  let payment = name;
  const tender = cdlid(tenderId);
  if (tender) {
    payment += ` (MP ID: ${tender.id})`;
  }
  return payment;
}

function formatDrainedTenderMealplanPayment(name: string, tenders: string) {
  return (name += ` (MP ID: ${tenders})`);
}

export function getTransactionPaymentMethod(
  order: CdlOrder,
  originalShoppingcart?: ShoppingCart,
  newTransaction?: RefundTransaction | any,
  originalTransaction?: any,
): string[] {
  const paymentMethods = [];
  const notAvailableString = 'N/A';
  const unknown = 'Unknown';
  if (!originalTransaction) {
    originalTransaction = order.meta.transaction;
  }
  if (!newTransaction) {
    newTransaction = originalTransaction;
  }

  if (!newTransaction?.id && !newTransaction?.meal_swipe && !newTransaction?.mealplan_swipes)
    return [getOrderPaymentType(order) || notAvailableString];

  const cashlessMethods = getCashlessPaymentMethodLabels(order);
  const walletType =
    newTransaction.digital_wallet_pay?.wallet_type ||
    originalTransaction?.digital_wallet_pay?.wallet_type;
  const isDigitalWallet = Boolean(walletType);
  const isCC = Boolean(
    !isDigitalWallet &&
      cashlessMethods.length === 0 &&
      (newTransaction.creditCard || originalTransaction.creditCard),
  );
  const isMealplanOrMx = Boolean(newTransaction.id && newTransaction.id === order.mealplan?.tender);
  const isMEQ = Boolean(newTransaction.id === order.meal_swipes?.tender);
  const isDualPayment = Boolean(
    !newTransaction.id && (newTransaction.meal_swipe || newTransaction.mealplan_swipes),
  );
  if (cashlessMethods.length) {
    paymentMethods.push(...cashlessMethods);
  }
  if (isDigitalWallet) {
    const cardType =
      order.payment?.credit_card?.card_type || originalTransaction?.creditCard?.cardType || unknown;
    paymentMethods.push(`${cardType}, ${walletType}`);
  }
  if (isCC) {
    const cardType =
      newTransaction.creditCard?.cardType ||
      originalTransaction?.creditCard?.cardType ||
      order.payment?.credit_card?.card_type ||
      unknown;
    const last4 =
      originalTransaction?.creditCard?.last4 || order.payment?.credit_card?.last4 || unknown;
    paymentMethods.push(`${cardType} **** ${last4}`);
  }
  if (isMealplanOrMx) {
    if (newTransaction.drained_tender_breakdown?.length > 0) {
      paymentMethods.push(
        formatDrainedTenderMealplanPayment(
          newTransaction.name || order.mealplan.name,
          newTransaction.drained_tender_breakdown
            .map((drained_tender: DrainedTender) => drained_tender.name)
            .join(', '),
        ),
      );
    } else {
      paymentMethods.push(
        formatMealplanPayment(
          newTransaction.name || order.mealplan.name,
          newTransaction.id || order.mealplan.tender,
        ),
      );
    }
  }
  if (isMEQ) {
    paymentMethods.push(
      formatMealplanPayment(
        newTransaction.name || order.meal_swipes.tender_name,
        newTransaction.id || order.meal_swipes.tender,
      ),
    );
  }
  if (isDualPayment) {
    const cart_payment = originalShoppingcart?.payment_method || order.shoppingcart?.payment_method;
    const secondaryOriginalTransaction =
      originalTransaction.digital_wallet_pay ||
      originalTransaction.credit_card ||
      originalTransaction.mealplan;
    const mealSwipeString = getTransactionPaymentMethod(
      order,
      originalShoppingcart,
      newTransaction.meal_swipe || originalTransaction.mealplan_swipes,
      originalTransaction.mealplan_swipes,
    )[0];
    const secondaryPaymentString = getTransactionPaymentMethod(
      order,
      originalShoppingcart,
      newTransaction.secondary_payment_refund || secondaryOriginalTransaction,
      secondaryOriginalTransaction,
    )[0];
    paymentMethods.push(
      `${mealSwipeString} ${currency(Number(cart_payment.meal_swipes?.total || 0).toFixed(2))}`,
    );
    paymentMethods.push(
      `${secondaryPaymentString} ${currency(
        Number(secondaryOriginalTransaction.amount || 0).toFixed(2),
      )}`,
    );
  }
  return paymentMethods;
}
export function isCountryRefundsEnabled(address: Address): boolean {
  const countryPrefix = getCountry(address);
  if (![CountryPrefix.USA, CountryPrefix.CANADA].includes(countryPrefix)) return false;
  if (countryPrefix === CountryPrefix.USA) return true;
  if (countryPrefix === CountryPrefix.CANADA) {
    const featureFlagStore = useSplitIoStore();
    const isFeatureFlagEnabled = featureFlagStore.isFeatureOn(FeatureFlags.CANADA_REFUNDS_ENABLED);
    return isFeatureFlagEnabled || false;
  }
  return false;
}

export function isPaymentMethodRefundsEnabled(
  order: CdlOrder,
  shoppingcart: ShoppingCart,
): boolean {
  // filter for existing payment methods
  const payment_methods: ShoppingCart['payment_method'] = shoppingcart.payment_method;
  const existing_payment_methods = Object.keys(payment_methods).filter(
    (k) => !isEmpty(payment_methods[k as keyof ShoppingCart['payment_method']]),
  );

  if (existing_payment_methods.length === 0) {
    return false;
  }

  if (existing_payment_methods.length > 1) {
    // split_tender_refund_logic
    // Currently the only provider that doesn't support split transaction MEQ refunds is transact
    return cdlid(shoppingcart?.payment_method.meal_swipes?.tender)?.provider !== 'blackboard';
  } else {
    // single_tender_refund_logic
    if (
      existing_payment_methods[0] === 'credit_card' ||
      existing_payment_methods[0] === 'digital_wallet_pay'
    ) {
      return true;
    }

    const existing_payment_method =
      payment_methods[existing_payment_methods[0] as keyof ShoppingCart['payment_method']];

    if ('tender' in existing_payment_method) {
      const provider = cdlid(existing_payment_method.tender).provider;
      return allowedRefunds[provider][existing_payment_methods[0]];
    }
  }
}

const allowedRefunds: Record<string, Record<string, boolean>> = {
  cbord: {
    mealplan: true,
    meal_swipes: true,
    meal_exchange: true,
  },
  blackboard: {
    mealplan: true,
    meal_swipes: false,
    meal_exchange: false,
  },
  atrium: {
    mealplan: true,
    meal_swipes: true,
    meal_exchange: false,
  },
  heartland: {
    mealplan: true,
    meal_swipes: false,
    meal_exchange: false,
  },
  itc: {
    mealplan: true,
    meal_swipes: false,
    meal_exchange: false,
  },
  fp: {
    badge_pay: true,
    stipend: false,
    voucher: false,
    coupon_voucher: false,
  },
};

export function getCountry(address: Address): CountryPrefix {
  return <CountryPrefix>address?.country;
}
export function getCashlessPaymentMethodLabels(order: CdlOrder | Order) {
  const cashlessMethods = [];
  const isBadgePay = Boolean(order.payment?.badge_pay?.name);
  const isStipend = Boolean(order.payment?.stipend?.name);
  const isVoucherPayment = Boolean(order.payment?.voucher?.name);
  const isCouponVoucherPayment = Boolean(order.payment?.coupon_voucher?.name);
  if (isBadgePay) {
    cashlessMethods.push(`${order.payment.badge_pay.name} (Badge Pay)`);
  }
  if (isStipend) {
    cashlessMethods.push(`${order.payment.stipend.name} (Stipend)`);
  }
  if (isVoucherPayment) {
    cashlessMethods.push(`${order.payment.voucher.name} (Voucher)`);
  }
  if (isCouponVoucherPayment) {
    cashlessMethods.push(`${order.payment.coupon_voucher.name} (Coupon Voucher)`);
  }
  return cashlessMethods;
}
