import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { DiscountsRouteNames, DiscountsRoutePathNames } from '../enums';
import { beforeEnter } from './guards/index';

const List = () => import('../components/list/List.vue');

const listRoute = {
  path: buildChildURL(DiscountsRoutePathNames.LIST),
  name: DiscountsRouteNames.DISCOUNTS_LIST,
  component: List,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [listRoute];

export default childRoutes;
