import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { DiscountsRouteNames, DiscountsRoutePathNames } from '../enums';

import childRoutes from './children';

const App = () => import('./../App.vue');

const rootRoute: RouteRecordRaw = {
  path: buildRootURL(DiscountsRoutePathNames.DISCOUNTS),
  name: DiscountsRouteNames.DISCOUNTS_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  children: childRoutes,
};

export default rootRoute;
