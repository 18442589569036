export enum LocalMenuGroupRouteNames {
  LOCAL_MENUS_ROOT = 'MenuRoot',
  LOCAL_MENUS = 'MenuRoot/Menus',
  LOCAL_MENUS_LIST = 'MenuRoot/Menus/List',
  LOCAL_MENU_DETAILS = 'MenuRoot/Menus/Details',
  BRAND_DETAILS = 'MenuRoot/Menus/Brand/Details',
  BRAND_MENUS = 'MenuRoot/Menus/Brand/Details/Views',
  BRAND_ITEMS = 'MenuRoot/Menus/Brand/Details/Items',
  BRAND_MODIFIERS = 'MenuRoot/Menus/Brand/Details/Modifiers',
  BRAND_UPDATES = 'MenuRoot/Menus/Brand/Details/Updates',
  BRAND_ACTIVITY_LOG = 'MenuRoot/Menus/Brand/Details/Activity',
  ENTITY_MANAGEMENT = 'MenuRoot/Menus/Brand/Details/EntityManagement',
  CATEGORY_CHILD_MANAGEMENT = 'MenuRoot/Menus/Brand/Details/Menu/Category/EntityManagement',
  MOD_GROUP_CHILD_MANAGEMENT = 'MenuRoot/Menus/Brand/Details/ModGroup/EntityManagement',
}
