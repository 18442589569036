export enum GlobalMenuGroupsRoutePathNames {
  BRANDS = 'brands',
  GLOBAL_MENU_GROUPS = 'global-menus',
  BRAND_DETAILS = 'brand',
  LIST = '',
  ID = ':id',
  BRAND_ID = ':brandId',
  MENUS = 'menus',
  MENUS_ID = ':menuId?',
  MENU_ID = ':menuId',
  CATEGORY = 'category',
  CATEGORY_ID = ':categoryId',
  ITEMS = 'items',
  MODIFIERS = 'modifiers',
  ENTITY = ':entity',
  ENTITY_ID = ':entityId',
}
