import type { RouteRecordRaw } from 'vue-router';

import groupslistRoute from './global-menu-group-list';
import detailsRoute from './global-menu-group-details';
import brandDetailsRoute from './brand-details';
import entityManagementRoutes from './manage-brand-sub-entity';

export default [
  groupslistRoute,
  detailsRoute,
  brandDetailsRoute,
  ...entityManagementRoutes,
] as RouteRecordRaw[];
