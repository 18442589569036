<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <InputCurrency
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
        ref="inputCurrency"
        :inputId="inputId"
        @keydown.enter="handleEnter"
        :options="{ ...DEFAULT_VUE_CURRENCY_INPUT_OPTIONS, ...options }"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
      </InputCurrency>
    </template>
  </FieldValidator>
</template>

<script lang="ts">
export default { name: 'CurrencyField', inheritAttrs: false };
</script>

<script setup lang="ts">
import { DEFAULT_VUE_CURRENCY_INPUT_OPTIONS, InputCurrency } from './../../../core/controls';
import { FieldValidator } from './../field-validator';
import { ref, type PropType } from 'vue';
import useSubmitFormProvider from '../../../core/controls/composables/input/use-submit-form-provider';
import type { CurrencyInputOptions } from 'vue-currency-input';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  inputId: {
    type: String,
  },
  options: {
    type: Object as PropType<Partial<CurrencyInputOptions>>,
    default: (): Partial<CurrencyInputOptions> => DEFAULT_VUE_CURRENCY_INPUT_OPTIONS,
  },
});

const inputCurrency = ref<InstanceType<typeof InputCurrency>>();

const { handleEnter } = useSubmitFormProvider();

defineExpose({ inputCurrency });
</script>
