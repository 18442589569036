import { SortOrder, type PaginationConfig, type RecordEntityId } from './../records/index';
import type { RecordEntity } from '../records';
import type { Attachments, Brand, GlobalMenuGroup } from '../brand';
import type { Site, Hours } from '../site';
export interface V2Menu {
  date: {
    created: string;
    modified: string;
    published: string;
  };
  id: string;
  label: V2MenuLabel;
  groups: V2MenuGroup[];
  name?: string;
  parent_id?: string;
  location_brand?: string;
  company?: string;
  sector?: string;
  is?: Record<string, any>;
  meta?: Record<string, any>;
}

export interface V2MenuGroup {
  id?: string;
  name: string;
  label: V2MenuLabel;
  items: ModifierV2[];
}

export interface ModifierV2 {
  id: string;
  description?: V2MenuLabel;
  label: V2MenuLabel;
  nutrition?: any;
  certified?: any;
  meta?: MenuMeta;
  price?: {
    amount: number;
  };
  LTOPromo?: {
    price: { amount: number };
  };
  _index?: string;
  uniqueRadioId?: string;
  modifierGroupInfo?: V2MenuLabel;
}

export interface V2MenuHours {
  id: string;
  label: V2MenuLabel;
  hours: Hours[];
  is_pickup?: boolean;
  is_delivery?: boolean;
  is_frictionless?: boolean;
}

export interface V2MenuLabel {
  en: string;
  fr?: string;
}

export interface MenuMeta {
  brand: RecordEntityId;
  local_menu_group: RecordEntityId;
}

export interface LocalMenuGroup extends RecordEntity {
  name: string;
  id: string;
  brand_id?: RecordEntityId;
  brand?: Brand;
  is_active?: boolean;
  allowed_global_menu_groups?: Partial<GlobalMenuGroup[]>;
  published_brands?: Brand[];
  meta?: {
    menu_type: MenuType;
  };
  draft_brands?: Brand[];
  version?: number;
  totalCategories?: number;
}

export enum MenuType {
  V2 = 'Admin Menu',
  CENTRIC_OS = 'Centric Menu',
}

export interface ModifierGroup extends RecordEntity {
  name: string;
  label?: string;
  min: number;
  max?: number;
  is_active?: boolean;
  parent_id?: RecordEntityId;
  brand_id?: RecordEntityId;
  items?: ItemToModifierGroupRelationship[];
  modifiers?: ModifierGroupToModifierRelationship[];
  priority?: number;
  version?: number;
  order_type: ModifierGroupOrderTypes;
  limit?: number;
  sizing?: ModifierGroupSizingOptions;
  duplicate_modifiers?: boolean;
  is_incremental?: boolean;
}

export interface Modifier extends RecordEntity {
  name: string;
  name_on_kds?: string;
  price: number;
  brand_id?: RecordEntityId;
  price_for_less?: number;
  price_for_more?: number;
  price_for_none?: number;
  pre_surcharge?: number;
  post_surcharge?: number;
  posid?: string;
  is_active: boolean;
  is_in_stock?: boolean;
  is_out_of_stock?: boolean;
  label?: string;
  calories?: number;
  description?: string;
  name_on_receipt?: string;
  tax_tags?: string[];
  tags?: string[];
  modifier_groups?: ModifierGroupToModifierRelationship[];
  modifier?: Modifier;
  priority?: number;
  weight?: Weight;
  reporting?: Reporting;
  version?: number;
  posid_segment?: string;
  parent_id?: RecordEntityId;
  attachments?: Attachments;
  menu_works?: MenuWorksEntity;
  tax_tag_code?: string;
  line_route?: ItemLineRoute;
}

export const enum ModifierGroupOrderTypes {
  SELECTION = 'selection',
  OPTION = 'option',
  QUANTITY = 'quantity',
}
export enum ModifierGroupSizingOptions {
  LESS_NORMAL_MORE = 'Less_Normal_More',
  NONE_NORMAL_EXTRA = 'None_Normal_Extra',
  SIDE_NORMAL_EXTRA = 'Side_Normal_Extra',
}

export interface ModifierBulk {
  id_list: RecordEntityId[];

  isActiveMultiple?: boolean;
  isNameMultiple?: boolean;
  isPriceMultiple?: boolean;
  isLabelMultiple?: boolean;
  isDescriptionMultiple?: boolean;
  isCaloriesMultiple?: boolean;
  isTagsMultiple?: boolean;
  isPriceForLessMultiple?: boolean;
  isPriceForMoreMultiple?: boolean;
  isPriceForNoneMultiple?: boolean;
  isPreSurchargeMultiple?: boolean;
  isPostSurchargeMultiple?: boolean;
  isLineRouteMultiple?: boolean;
  isTaxTagsMultiple?: boolean;
  isModifierGroupsMultiple?: boolean;
  isTaxTagCodeMultiple?: boolean;

  is_active?: boolean;
  name?: string;
  price?: number;
  price_for_less?: number;
  price_for_more?: number;
  price_for_none?: number;
  pre_surcharge?: number;
  post_surcharge?: number;
  reporting?: ReportingBulk;
  label?: string;
  description?: string;
  calories?: number;
  tax_tags?: string[];
  tags?: string[];
  weight?: Weight;
  modifier_groups?: ModifierGroupToModifierRelationship[];
  line_route?: ItemLineRoute;
  tax_tag_code?: string;
}

export interface Weight {
  unit?: WeightUnit;
  amount?: number;
  isWeightAmountMultiple?: boolean;
  isWeightUnitMultiple?: boolean;
}

export interface Reporting {
  category?: ReportingCategory;
}

export interface ReportingCategory {
  primary?: ReportPrimaryCategory;
  secondary?: string;
}

export interface ReportingBulk {
  category?: ReportingCategoryBulk;
}

export interface ReportingCategoryBulk {
  primary?: ReportPrimaryCategory;
  secondary?: string;
  isReportingPrimaryMultiple?: boolean;
  isReportingSecondaryMultiple?: boolean;
}

export interface ReportingCategoryResponse extends RecordEntity {
  category?: string;
  occurrences?: number;
}

export enum JwoTaxCode {
  A_GEN_NOTAX = 'A_GEN_NOTAX',
  A_FOOD_GEN = 'A_FOOD_GEN',
  A_FOOD_CNDY = 'A_FOOD_CNDY',
  A_FOOD_CNDYFL = 'A_FOOD_CNDYFL',
  A_FOOD_BKTGN76_90 = 'A_FOOD_BKTGN76-90',
  A_FOOD_BKTGN50_75 = 'A_FOOD_BKTGN50-75',
  A_FOOD_JUICE0_50 = 'A_FOOD_JUICE0-50',
  A_FOOD_JUICE51_99 = 'A_FOOD_JUICE51-99',
  A_FOOD_CARBSFTDK = 'A_FOOD_CARBSFTDK',
  A_FOOD_CARBWTR = 'A_FOOD_CARBWTR',
  A_FOOD_NCARBWTR = 'A_FOOD_NCARBWTR',
  A_FOOD_WINE = 'A_FOOD_WINE',
}

export enum JwoTaxDescription {
  A_GEN_NOTAX = 'Always Nontaxable',
  A_FOOD_GEN = 'General Food',
  A_FOOD_CNDY = 'Candy',
  A_FOOD_CNDYFL = 'Candy Containing Flour',
  A_FOOD_BKTGN76_90 = 'General Food Basket 76-90%',
  A_FOOD_BKTGN50_75 = 'General Food Basket 50-75%',
  A_FOOD_JUICE0_50 = '0-50% Juice Content',
  A_FOOD_JUICE51_99 = '51-99% Juice Content',
  A_FOOD_CARBSFTDK = 'Carbonated Soft Drinks',
  A_FOOD_CARBWTR = 'Carbonated Water',
  A_FOOD_NCARBWTR = 'Non-Carbonated Water',
  A_FOOD_WINE = 'Wine',
}

export enum ReportPrimaryCategory {
  ALCOHOL = 'Alcohol',
  FOOD = 'Food',
  BEVERAGE = 'Beverage',
  SNACK = 'Snack',
  SUNDRY = 'Sundry',
}

export enum WeightUnit {
  LB = 'lb',
  OZ = 'oz',
  KG = 'kg',
  G = 'g',
}

export enum ItemLineRoute {
  GRILL = 'Grill',
  DELI = 'Deli',
  PIZZA = 'Pizza',
  PASTA = 'Pasta',
  SALAD = 'Salad',
  ENTREE = 'Entree',
  EXPO = 'Expo',
  COFFEE = 'Coffee',
  SUSHI = 'Sushi',
  BREAKFAST = 'Breakfast',
  CREATE = 'Create',
  EVERYTHING = 'Everything',
}
export interface LineRoute extends RecordEntity {
  line_route?: string;
  occurrences?: number;
}

export interface ModifierGroupToModifierRelationship extends RecordEntity {
  modifier_id: RecordEntityId;
  modifier_group_id: RecordEntityId;
  brand_id?: RecordEntityId;
  sequence?: number;
  modifier_group?: ModifierGroup;
  modifier?: Modifier;
  version?: number;
}

export interface Item extends RecordEntity {
  name: string;
  name_on_kds?: string;
  price: number;
  reporting: Reporting;
  label?: string;
  description?: string;
  barcode?: string;
  calories?: number;
  is_active?: boolean;
  is_in_stock?: boolean;
  is_out_of_stock?: boolean;
  posid?: string;
  name_on_receipt?: string;
  tax_tag_code?: string;
  tax_tags?: string[];
  tags?: string[];
  modifier_groups?: ItemToModifierGroupRelationship[];
  categories?: CategoryToItemRelationship[];
  priority?: number;
  weight?: Weight;
  line_route?: ItemLineRoute;
  attachments?: Attachments;
  version?: number;
  posid_segment?: string;
  parent_id?: RecordEntityId;
  menu_works?: MenuWorksEntity;
  is_featured?: Boolean;
  meal_value?: string;
  tax_jwo_code?: JwoTaxCode;
  brand_id?: RecordEntityId;
  base_item_id?: string;
}

export interface Activity extends RecordEntity {
  date?: string;
  message?: string;
  process_id?: string;
  downloadLink?: string;
}

export interface MenuWorksResponse {
  calories?: number;
  name: string;
  description: string;
  MRN: string;
  unit_id: string;
  portion_quantity: number;
  portion_unit_name: string;
  grams: number;
  cost: number;
  ingredients: string[];
  smart_tags: string[];
  allergen_tags: string[];
}

export interface MenuWorksEntity {
  MRN: string;
  unit_id?: string;
  portion_quantity: number;
  portion_unit_name: string;
  grams?: number;
  cost: number;
  ingredients: string[];
  smart_tags: string[];
  allergen_tags: string[];
}

export enum ItemImportErrorFields {
  NAME = 'name',
  PRICE = 'price',
  LABEL = 'label',
  REPORTING = 'reporting',
  DESCRIPTION = 'description',
  BARCODE = 'barcode',
  CALORIES = 'calories',
  IS_ACTIVE = 'is_active',
  TAX_TAGS = 'tax_tags',
  TAGS = 'tags',
  WEIGHT_UNIT = 'weight',
  WEIGHT_AMOUNT = 'weight_amount',
  LINE_ROUTE = 'line_route',
  CATEGORY_ID = 'category_id',
  ID = 'id',
  JWO_TAX_CODE = 'tax_jwo_code',
  UNKNOWN = 'unknown',
}

export interface ItemBulk {
  id_list: RecordEntityId[];

  isNameMultiple?: boolean;
  isPriceMultiple?: boolean;
  isMealValueMultiple?: boolean;
  isLabelMultiple?: boolean;
  isDescriptionMultiple?: boolean;
  isCaloriesMultiple?: boolean;
  isTaxTagsMultiple?: boolean;
  isTagsMultiple?: boolean;
  isModifierGroupsMultiple?: boolean;
  isLineRouteMultiple?: boolean;
  isActiveMultiple: boolean;
  isFeaturedMultiple?: boolean;
  isTaxTagCodeMultiple?: boolean;

  is_active?: boolean;
  name?: string;
  price?: number;
  meal_value?: number;
  reporting?: ReportingBulk;
  label?: string;
  description?: string;
  calories?: number;
  tax_tags?: string[];
  tags?: string[];
  modifier_groups?: ItemToModifierGroupRelationship[];
  weight?: Weight;
  line_route?: ItemLineRoute;
  is_featured?: boolean;
  tax_jwo_code?: JwoTaxCode;
  tax_tag_code?: string;
}

export interface ItemToModifierGroupRelationship extends RecordEntity {
  item_id: RecordEntityId;
  modifier_group_id: RecordEntityId;
  modifier_group?: ModifierGroup;
  brand_id?: RecordEntityId;
  name?: string;
  item?: Item;
  sequence?: number;
  version?: number;
  parent_id?: RecordEntityId;
}

export interface Category extends RecordEntity {
  name: string;
  label?: string;
  sequence?: number;
  priority?: number;
  is_active?: boolean;
  items?: CategoryToItemRelationship[];
  version?: number;
  attachments?: Attachments;
  menu_id?: RecordEntityId;
  brand_id?: RecordEntityId;
  created_at: string;
}

export interface CategoryToItemRelationship extends RecordEntity {
  category_id?: RecordEntityId;
  item_id?: RecordEntityId;
  brand_id?: RecordEntityId;
  parent_id?: RecordEntityId;
  sequence?: number;
  category?: Category;
  item?: Item;
  version?: number;
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
}

export interface Menu extends RecordEntity {
  id: RecordEntityId;
  name: string;
  brand?: Brand;
  brand_id?: RecordEntityId;
  created_at?: string;
  station?: Station;
  categories?: Category[];
  version?: number;
}

export interface Station extends RecordEntity {
  name: string;
  site?: Site;
}

export const defaultMenuNavigationPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
    name: null,
    station: null,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export const defaultMenuRecordPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
    name: null,
    price: null,
    posid: null,
    barcode: null,
    is_active: null,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export const defaultCategoryToItemPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'sequence',
    sortOrder: SortOrder.ASC,
    name: null,
    price: null,
    posid: null,
    barcode: null,
    is_active: null,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export const defaultActivityRecordPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'created_at',
    sortOrder: SortOrder.DESC,
    date: null,
    message: null,
    processId: null,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export const defaultMenusNavigationPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export const defaultCpgItemsPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
    name: null,
    barcode: null,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export interface MenuQueryParams extends Record<string, string | number | Object> {
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  name?: string;
  price?: number;
  posid?: string;
  barcode?: string;
  is_active?: boolean;
  station?: Object;
  filter?: Object;
}

export interface FilterQueryParams extends Record<string, string | number | Object> {
  name?: Object;
  price?: Object;
  posid?: Object;
  barcode?: Object;
  is_active?: Object;
  station?: { name: Object };
}
export interface V3Menu extends V2Menu {
  brand?: string;
}

export interface ValidateItemsPayload {
  brand_id: RecordEntityId;
  data: File;
}
export interface ImportItemsPayload {
  brand_id: RecordEntityId;
  data: string;
}
export interface ImportItemsValidationResponse {
  s3_link: string;
}

export enum TaxTags {
  Alcohol = 'Alcohol',
  BakedGoods = 'Baked Goods',
  Candy = 'Candy',
  CarbonatedBeverage = 'Carbonated Beverage',
  TaxExempt = 'Tax Exempt',
  Pop = 'Pop',
  Prepared = 'Prepared',
  SweetenedBeverage = 'Sweetened Beverage',
  Unprepared = 'Unprepared',
  Water = 'Water',
  Meal = 'Meal',
  Good = 'Good',
}

export interface UniversalCPGItem extends RecordEntity {
  barcode: string;
  name: string;
  description?: string;
  created_at?: string | number | Date;
  updated_at?: string | number | Date;
  is_verified?: boolean;
  verified_at?: Date;
  verified_by?: string;
  images?: Record<string, any>;
  metadata: Record<string, any>;
  product_number?: number;
}

export interface MenuPublishError {
  barcode: string;
  items: Item[];
  type: string;
}

export interface GroupExportSolution<T = string | string[]> {
  id: string;
  name: string;
  format: T;
}

export enum BrandSubEntities {
  MENU = 'menu',
  CATEGORY = 'category',
  ITEM = 'item',
  MODIFIER_GROUP = 'modifier-group',
  MODIFIER = 'modifier',
}

export interface ExportBrandResponse {
  s3_link: string;
}
export interface ImportLinkBrandResponse {
  upload_link: string;
}
