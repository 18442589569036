import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { ReportsRouteNames, ReportsRoutePathNames } from '../enums';

const Refunds = () => import('../components/refunds/Refunds.vue');

const refundsRoute = {
  path: buildChildURL(ReportsRoutePathNames.REFUND),
  name: ReportsRouteNames.REFUND,
  component: Refunds,
} as RouteRecordRaw;

const SalesDistribution = () => import('../components/sales-distribution/SalesDistribution.vue');

const salesDistributionRoute = {
  path: buildChildURL(ReportsRoutePathNames.SALES_DISTRIBUTION),
  name: ReportsRouteNames.SALES_DISTRIBUTION,
  component: SalesDistribution,
} as RouteRecordRaw;

const SalesMix = () => import('../components/sales-mix/SalesMix.vue');

const salesMixRoute = {
  path: buildChildURL(ReportsRoutePathNames.SALES_MIX),
  name: ReportsRouteNames.SALES_MIX,
  component: SalesMix,
} as RouteRecordRaw;

const SalesSummary = () => import('../components/sales-summary/SalesSummary.vue');

const salesSummaryRoute = {
  path: buildChildURL(ReportsRoutePathNames.SALES_SUMMARY),
  name: ReportsRouteNames.SALES_SUMMARY,
  component: SalesSummary,
} as RouteRecordRaw;

const TerminalSales = () => import('../components/terminal-sales/TerminalSales.vue');

const terminalSalesRoute = {
  path: buildChildURL(ReportsRoutePathNames.TERMINAL_SALES),
  name: ReportsRouteNames.TERMINAL_SALES,
  component: TerminalSales,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [
  refundsRoute,
  salesDistributionRoute,
  salesMixRoute,
  salesSummaryRoute,
  terminalSalesRoute,
];

export default childRoutes;
