import { default as brandManagementEn } from '@centric-os/brand-management/src/locale/en';
import { navigationEn } from './navigation-en';

export default {
  buttons: {
    change: 'Change',
    discard: 'Discard',
    save: 'Save',
    select: 'Select',
  },
  inputSwitch: {
    active: 'Active',
    inactive: 'Inactive',
  },
  documentTitleWrapper: '{title} - CentricOS',
  errorMessages: {
    400: 'Not found',
    404: 'Not found',
    500: 'Bad Request',
    503: 'Server Error',
    genericRequired: 'Required',
    genericApiError: 'We had an error on our side, please try again later.',
    apiError: 'An error occurred on server: {error}',
    unauthorized: 'User is not authorized.',
    checkInformation: 'Check the information you entered',
    email: 'Enter a valid email address',
    fileLimit: 'Only one file can be selected',
    fileSize: 'File cannot be bigger than {maxFileSize}',
    fileType: 'File format not supported',
    imageFileType: 'Image format not supported',
    max: 'Reached max character count: {count}',
    maxValue: 'Reached Max of {maxValue}',
    minValue: 'Requires min of {minValue} {record}',
    greaterThan: 'Must be greater than {greaterThan}',
    lessThan: 'Must be less than {lessThan}',
    required: 'This is a required field',
    startSpace: 'Value cannot start with empty spaces',
    endSpace: 'Value cannot end with empty spaces',
    invalidIP: 'Invalid IP address',
    invalidURL: 'Invalid URL',
    invalidType: 'Must be only {type}',
    invalidDomain: 'Invalid domain',
    calendar: {
      endDateBeforeStartDate: 'End date and time must be after the start date and time',
      pickupOrDropoff: 'Pickup or drop-off is required',
      repeat: 'Repeat is required',
    },
    invalidNumber: 'Must be a valid number',
    form: {
      invalidFormSubmit: 'Please correct the errors in the form!',
      fields: {
        error: '{field}: {error}',
        formatedError: '{0}',
      },
    },
  },
  multiElementSelect: {
    tooltip: `Select multiple items fast! Click the first item, hold Shift, \n then click a second. All items in between get selected.`,
  },
  calendar: {
    todayLabel: 'Today',
    yesterdayLabel: 'Yesterday',
    currentWeekLabel: 'Current Week',
    lastWeekLabel: 'Last Week',
    currentMonthLabel: 'Current Month',
    lastMonthLabel: 'Last Month',
    clear: 'Clear',
    today: 'Today',
  },
  imageSelect: {
    dragMessage: 'Drag and drop .png or .jpg',
    emptyMessage: 'Upload image',
    remove: 'Remove image',
    infoMessage: 'You can upload jpg, jpeg and png',
  },
  fileSelect: {
    filesOnly: '({fileType} files only)',
    uploadFile: 'Upload file',
    remove: 'Remove',
  },
  tableFilter: {
    search: 'Search',
    selectedItemsLabel: '{total} {value} selected',
  },
  dropdown: {
    search: 'Search',
  },
  na: 'N/A',
  pendingUserName: 'Pending User',
  statusCard: {
    header: 'Status',
    active: 'Active',
    inactive: 'Inactive',
  },
  tags: {
    active: 'Active',
    inactive: 'Inactive',
  },
  refundStatus: {
    full: 'Fully Refunded',
    partial: 'Partially Refunded',
  },
  user: {
    fullName: '{firstName} {lastName}',
    pending: 'Pending User',
  },
  deleteFooter: {
    label: 'Danger Zone',
    action: 'Delete',
  },
  deleteDialog: {
    header: 'Delete Record',
    messageAlert: 'Are you sure you want to delete?',
    acceptLabel: 'Delete',
    rejectLabel: 'Cancel',
  },
  confirmDeleteDialog: {
    header: 'Delete {record}?',
    body: 'Once you delete {record}, it will be permanently erased. Type the {type} name and click Delete to confirm.',
    placeholder: 'Enter {type} name to delete',
    deleteLabel: 'Delete',
    cancelLabel: 'Cancel',
  },
  discardDialog: {
    header: 'Discard Changes',
    createMessageAlert: 'All changes you made will be lost.',
    editMessageAlert: 'All changes you made will be reverted.',
    acceptLabel: 'Discard',
    rejectLabel: 'Cancel',
  },
  leaveDialog: {
    header: 'You have unsaved changes',
    message: 'All changes will be lost. Are you sure you want to leave?',
    acceptLabel: 'Leave',
    rejectLabel: 'Stay',
  },
  errorDialog: {
    header: 'Correct the error(s) and save again',
    message: 'You have errors in the {tab} tab.',
    acceptLabel: 'Correct errors',
    rejectLabel: '',
  },
  cancelForm: {
    header: 'You have unsaved changes',
    message: 'If you cancel, all changes will be lost.',
    acceptLabel: 'Leave',
    rejectLabel: 'Stay',
  },
  form: {
    actions: {
      save: 'Save',
      cancel: 'Cancel',
    },
    edit: {
      title: 'You have unsaved changes',
    },
  },
  barcodeScannerSplashDialog: {
    title: 'Scan Barcodes On-The-Go',
    content:
      'Use your phone to scan barcodes, updates prices, and create items. For bigger tasks, switch to your desktop.',
    closeButtonLabel: 'Not now',
    redirectButtonLabel: 'Start scanning',
  },
  ...navigationEn,
  ...brandManagementEn,
};
