import { CurrencyDisplay, type CurrencyInputOptions } from 'vue-currency-input';

export const DEFAULT_VUE_CURRENCY_INPUT_OPTIONS: CurrencyInputOptions = {
  currency: 'USD',
  currencyDisplay: CurrencyDisplay.hidden,
  precision: 2,
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: true,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: true,
  useGrouping: true,
  accountingSign: false,
  valueRange: {
    min: 0,
  },
};
