<template>
  <div :class="rootClasses">
    <AutoComplete
      v-model="selectedSuggestion"
      :suggestions="filteredSuggestions"
      @complete="search($event)"
      @item-select="addToListBox"
      :field="suggestionsField"
      :disabled="disabled"
      :required="required"
      :placeholder="placeholder"
      :class="autocompleteClasses"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      @focus="focused = true"
      @blur="focused = false"
      v-bind="$attrs"
    >
      <template v-if="label" #label>{{ label }}</template>
      <template #startAdorment>
        <i class="material-symbols-rounded text-2xl">search</i>
      </template>
    </AutoComplete>

    <div :class="listBoxClasses" @mouseover="hovered = true" @mouseleave="hovered = false">
      <div ref="listWrapper" class="p-listbox-list-wrapper">
        <ul :class="['p-listbox-list', 'pt-0']" :tabindex="-1" role="listbox">
          <template
            v-for="(option, i) of value"
            :key="getOptionRenderKey(option, getOptionIndex(i))"
          >
            <li
              :class="['p-listbox-item', 'p-4', 'border-bottom-1', 'border-solid', 'border-300']"
              role="option"
            >
              <div class="flex flex-wrap justify-content-between option-base">
                <div class="flex align-items-center">
                  <slot name="option" :option="option">
                    {{ getOptionLabel(option) }}
                  </slot>
                </div>
                <div class="flex align-items-center">
                  <span
                    class="material-symbols-rounded cursor-pointer text-2xl"
                    @click="removeOption(getOptionIndex(i))"
                  >
                    close
                  </span>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, useAttrs, watchEffect, computed } from 'vue';
import { AutoComplete } from './../autocomplete';
import { ObjectUtils } from 'primevue/utils';
import type { InputAttrs } from '@centric-os/types';
import type { AutoCompleteItemSelectEvent } from 'primevue/autocomplete';

const emit = defineEmits(['search', 'item-select', 'item-remove']);

defineOptions({
  name: 'AutoCompleteBox',
});

const props = defineProps({
  label: {
    type: String,
  },
  helperText: {
    type: String,
  },
  filteredSuggestions: {
    type: Array,
  },
  suggestionsField: {
    type: String,
  },
  listBoxDataKey: {
    type: String,
  },
  optionLabel: {
    type: String,
  },
  optionValue: null,
  optionDisabled: null,
  removeHandler: {
    type: Function,
  },
});

const selectedSuggestion = ref<any>(null);

const value = defineModel<any>();

const search = (value: any) => {
  emit('search', value?.query);
};

const addToListBox = (event: AutoCompleteItemSelectEvent) => {
  emit('item-select', event);
  if (value.value?.length >= 0) {
    value.value.push(event?.value);
  } else {
    value.value = [event?.value];
  }

  selectedSuggestion.value = null;
};

const getOptionLabel = (option): string => {
  return props.optionLabel ? ObjectUtils.resolveFieldData(option, props.optionLabel) : option;
};

const getOptionRenderKey = (option, index): string => {
  return (
    (props.listBoxDataKey
      ? ObjectUtils.resolveFieldData(option, props.listBoxDataKey)
      : getOptionLabel(option)) +
    '_' +
    index
  );
};

const getOptionIndex = (index): number => {
  return index;
};

const attrs = useAttrs();

const disabled = ref(false);
const required = ref(false);
const placeholder = ref<string>(null);

watchEffect(() => {
  disabled.value = Boolean(attrs.disabled);
  required.value = Boolean(attrs.required);
  placeholder.value = String(attrs.placeholder);
});

const autocompleteClasses = computed(() => [
  'c-autocomplete',
  (attrs as InputAttrs).class,
  {
    'p-focus': focused.value,
    'c-input-hovered': hovered.value,
  },
]);

const listBoxClasses = computed(() => [
  'p-listbox',
  'p-component',
  'w-full',
  'border-noround-top',
  'border-top-none',
  {
    'p-disabled': disabled.value,
  },
]);

const focused = ref(false);
const hovered = ref(false);

const rootClasses = computed(() => [
  'autocomplete-box',
  'p-field',
  'w-full',
  {
    ...autocompleteClasses.value,
    'c-autocomplete-box-focus': focused.value,
    'c-autocomplete-box-hovered': hovered.value,
    'c-autocomplete-box-disabled': disabled.value,
  },
]);

const removeOption = (index: number): void => {
  emit('item-remove', index);
  if (props.removeHandler) {
    return props.removeHandler(index, value);
  }
  value.value.splice(index, 1);
};

defineExpose({ value });
</script>

<style scoped lang="scss">
:deep(.c-autocomplete) {
  .p-autocomplete-input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.p-listbox {
  min-height: 100px;
  max-height: 350px;
  overflow: auto;
}

.option-base {
}
</style>
