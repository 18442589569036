import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../enums';

const Component = () => import('./ManageBrandSubEntity.vue');

export default [
  {
    path: buildChildURL(
      LocalMenuGroupRoutePathNames.ID,
      LocalMenuGroupRoutePathNames.BRAND_DETAILS,
      LocalMenuGroupRoutePathNames.BRAND_ID,
      LocalMenuGroupRoutePathNames.ENTITY,
      LocalMenuGroupRoutePathNames.ENTITY_ID,
      LocalMenuGroupRoutePathNames.MODE,
    ),
    name: LocalMenuGroupRouteNames.ENTITY_MANAGEMENT,
    component: Component,
  },
  // Route for an item opened from a Category
  {
    path: buildChildURL(
      LocalMenuGroupRoutePathNames.ID,
      LocalMenuGroupRoutePathNames.BRAND_DETAILS,
      LocalMenuGroupRoutePathNames.BRAND_ID,
      LocalMenuGroupRoutePathNames.MENUS,
      LocalMenuGroupRoutePathNames.MENU_ID,
      LocalMenuGroupRoutePathNames.CATEGORY,
      LocalMenuGroupRoutePathNames.CATEGORY_ID,
      LocalMenuGroupRoutePathNames.ENTITY,
      LocalMenuGroupRoutePathNames.ENTITY_ID,
      LocalMenuGroupRoutePathNames.MODE,
    ),
    name: LocalMenuGroupRouteNames.CATEGORY_CHILD_MANAGEMENT,
    component: Component,
  },
  // When we do Modifiers, we can do Modifier group below with the same component
] as RouteRecordRaw[];
